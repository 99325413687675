import _ from 'lodash'

import template from '../../handlebars/login.handlebars'
import * as pubsub from '../utils/pubsub.js'
import * as ajax from '../utils/ajax-methods.js'
import * as Notifications from '../ui/notifications.js'

import * as views from './views.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  dom: {
    login: document.getElementById('login-page')
  }
}

/* ----------------------------- Public methods ----------------------------- */

const init = () => {
  pubsub.subscribe('logout', logout)
}

/* ----------------------------- Local methods ------------------------------ */

const logout = async () => {
  // TODO: this should not be in login module
  const response = await ajax.getLoginMethods()
  if (response.status !== 200) {
    Notifications.error(response.statusText)
    return
  }

  render(response.data)
  views.switchToLogin()
}

/* ----------------------------- Pure functions ----------------------------- */

const render = (loginMethods) => {
  const templateData = {
    loginMethods: _.sortBy(loginMethods, 'title'),
    year: new Date().getFullYear()
  }

  const html = template(templateData)
  conf.dom.login.innerHTML = html
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
