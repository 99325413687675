import * as pubsub from '../utils/pubsub.js'

/*
	registrace, zpracovani a zachytavani udalosti klavesnice
*/

/*
 mapovani modifikacnich klaves do bitove masky
  -----------------------------
  | alt | ctrl | shift | meta |
  -----------------------------
  |  8  |   4  |   2   |   1  |
  -----------------------------
  
  alt+ctrl  = 12
  alt+shift = 10
  alt+meta  =  9
  
  alt+ctrl+shift = 16
  alt+meta       =  9
  ... a tak dale ...
  */

/* definice modifikacnich klaves */
const MOD_KEYS = ["altKey","ctrlKey","shiftKey","metaKey"].reverse();

class ShortCuts {
	constructor() {
		window.addEventListener("keydown", this,false);
		pubsub.subscribe("content.keydown",this.handleKeyDown.bind(this));
		
		/* zasobnik definic zkratek  typ udalosti -> klavesa -> modifikacni klavesy jako int -> identifikator handleru*/
		this._handleMap = {
			"keyup":{},
			"keydown":{}, /*"a" + 12*/
			"keypress":{}
		}
		/* zasobnik callbacku  identifikator callbacku -> handler, types - pole zkratek jako int definujici modifikacni klavesy a klavesa*/
		this._handlers = {}
		
		/* ukazka zadani klavesove zkratky 
		this.setShortCut({
			type:"keydown",
			key:"q",
			modifyKeys:["altKey","ctrlKey"],
			handler:this._toggleMenuColumn});
		*/
	}
	
	/* handler udalosti */
	handleEvent(e) {
		if(e.type in this._handleMap) {
			this._handleProcess(e);
		}
	}
	
	/* handler signalu */
	handleKeyDown(e) {
		if(e.event) {
			this._handleProcess(e.event);
		}
	}
	
	/* vraci pole definic hlidanych klaves pro pripadne zretezeni do napovedy */
	/* FIXME mozna neni potreba */
	getShortCutKeys(id,type) {
		var data = this._handlers[id].types[type];
		var keys = MOD_KEYS;
		var out = [];
		keys.forEach((item,index) => {
			if(data[0] & 1 << index) {
				out.push(keys[index]);
			}
		});
		return out.push(data[1]);
	}
	
	/* zaregistruje handlovani konkretni klavesove zkratky */
	/* format dat:
	   type - (string), typ udalosti
	   key - (string), klavesa [event.key]
	   modifyKeys - (Array)(string), pole vyzadovanych modifikacnich klaves jak jsou pojmenovane v udalosti klavesnice
	   								 "altKey" || "ctrlKey" || "shiftKey" || "metaKey"
	   handler - (function) callback
	*/
	setShortCut(data) {
		var id = "i_" + new Date().getTime() + "_" + Math.floor(Math.random() * 1000);
		if(!this._handleMap[data.type]) {
			this._handleMap[data.type] = {};
		}

		this._handleMap[data.type][data.key] =  [];
		var modId = this._modifyKeysToNumber(data.modifyKeys);
		this._handleMap[data.type][data.key][modId] = id;
		
		if(!this._handlers[id]) {
			this._handlers[id] = {
				handler:"",
				types:{}
			};
		}
		this._handlers[id].handler = data.handler;
		this._handlers[id].types[data.type] = [data.modifyKeys,data.key];
		return id;
	}
	
	/* odebrani klavesove zkratky, data ve stejne podobe jako pri pridani neni potreba atribut handler */
	removeShortCut(data) {
		var modId = this._modifyKeysToNumber(data.modifyKeys);
		if(this._handleMap[data.type] 
		&& this._handleMap[data.type][data.key] 
		&& this._handleMap[data.type][data.key][modId]) {
			var id = this._handleMap[data.type][data.key][modId];
			this._handleMap[data.type][data.key] = null;
			delete(this._handleMap[data.type][data.key]);
			
			if(this._handlers[id]) {
				this._handlers[id] = null;
				delete(this._handlers[id]);
			}
		}
	}
	
	removeShortCutById(id) {
		var type = Object.keys(this._handlers[id].types)[0];
		var modId = this._modifyKeysToNumber(this._handlers[id].types[type][0]);
		var key = this._handlers[id].types[type][1];
		this._handlers[id] = null;
		delete(this._handlers[id]);
		
		this._handleMap[type][key][modId] = null;
		delete(this._handleMap[type][key][modId]);
	}
	
	/* prevede definici modifikacnich klaves pro zkratku na cislo */
	_modifyKeysToNumber(modifyKeys) {
		var num = 0;
		for(var i = 0; i < modifyKeys.length;i++) {
			var index = MOD_KEYS.indexOf(modifyKeys[i]);
			if (index < 0) { continue }
			num = num | 1 << index;
		}
		return num;
	}
	
	/* zpracovani klaves zachycenych udalosti, nebo predanych signalem */
	_handleProcess(e) {
		var keys = MOD_KEYS;
		var mod = 0
		keys.forEach((item,index) => {
			var num = 0
			if(e[keys[index]]) {
				num =  1 << index;
			}
			mod = mod | num;
		});
		
		if(mod && this._handleMap[e.type] 
		&& this._handleMap[e.type][e.key] 
		&& this._handleMap[e.type][e.key][mod]) {
			var handlerId = this._handleMap[e.type][e.key][mod];
			this._handlers[handlerId].handler();
		}
	}
	
	/* test
	_toggleMenuColumn() {
		console.log("NAZDAR")
	}
	*/
	
}

var shortCuts = new ShortCuts();

export default shortCuts;
