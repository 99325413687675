import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

const dom = {
  menu: {
    wrapperEl: document.getElementById('menu-wrapper'),
    shadowEl: document.getElementById('menu-shadow'),
    shadowClass: 'sidebar__content-bottom-shadow--visible'
  },
  content: {
    shadowEl: document.getElementById('content-shadow'),
    shadowClass: 'content__shadow--visible'
  }
}

/* ----------------------------- Public methods ----------------------------- */

const init = () => {
  dom.menu.wrapperEl.addEventListener('scroll', menuScrollHandler)
  pubsub.subscribe('content.display', contentDisplayHandler)
  pubsub.subscribe('content.destroy', contentDestroyHandler)
}

/* ----------------------------- Local methods ------------------------------ */

const menuScrollHandler = (e) => {
  const showShadow = e.target.scrollTop > 0
  handleShadow(dom.menu.shadowEl, dom.menu.shadowClass, showShadow)
}

const contentDisplayHandler = ({ iframe }) => {
  try {
    iframe.contentWindow.addEventListener('scroll', iframeScrollHandler)
  } catch (e) {}
}

const contentDestroyHandler = ({ iframe }) => {
  handleShadow(dom.content.shadowEl, dom.content.shadowClass, false)
  try {
    // TODO: try/catch needed in Firefox when we display in iframe page
    // which forbids displaying in iframe. Document this somewhere
    iframe.contentWindow.removeEventListener('scroll', iframeScrollHandler)
  } catch (e) {}
}

const iframeScrollHandler = (e) => {
  const bodyEl = e.target.body
  const showShadow = bodyEl.scrollTop > 0
  handleShadow(dom.content.shadowEl, dom.content.shadowClass, showShadow)
}

/* ----------------------------- Pure functions ----------------------------- */

const handleShadow = (el, className, add) => {
  el.classList[add ? 'add' : 'remove'](className)
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
