/* -------------------------- Config & local state -------------------------- */

const conf = {
  debug: false,
  delay: 0
}

const state = {
  start: Date.now(),
  counter: {
    publish: {},
    subscribe: {},
    unsubscribe: {}
  },
  cbStorage: {}
}

/* ----------------------------- Public methods ----------------------------- */

const subscribe = function (eventName, cb) {
  log('subscribe', { eventName, callbackName: cb.name })
  if (cb === undefined) {
    console.warn(`pubsub.js[subscribing to '${eventName}']: Second callback parameter is missing`)
    return
  } else if (typeof cb !== 'function') {
    console.warn(`pubsub.js[subscribing to '${eventName}']: Second callback parameter is not a function`)
    return
  }

  if (!state.cbStorage[eventName]) {
    state.cbStorage[eventName] = []
  }

  state.cbStorage[eventName].push(cb)

  // return function to remove subscription
  return () => {
    log('unsubscribe', { eventName, callbackName: cb.name })
    state.cbStorage[eventName] = state.cbStorage[eventName].filter(x => x !== cb)
  }
}

const publish = function (eventName, data) {
  log('publish', { eventName, data })
  if (!state.cbStorage[eventName] || !state.cbStorage[eventName].length) return

  state.cbStorage[eventName].forEach((cb) => {
    cb(data)
  })
}

/* ----------------------------- Local methods ------------------------------ */

const stringToHex = (str) => {
  const hash = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  const hex = (hash(str) & 0x00FFFFFF).toString(16)
  return '#' + '00000'.substring(0, 6 - hex.length) + hex
}

const log = (type, { eventName, callbackName, data }) => {
  if (!conf.debug) return

  if (!state.counter[type][eventName]) state.counter[type][eventName] = 0
  state.counter[type][eventName]++

  if (Date.now() - state.start < conf.delay) return

  const texts = {
    event: `\n%c\t%c event:\t\t\t%c${eventName}%c`,
    published: `\n%c\t%c published:\t\t${state.counter['publish'][eventName]}×`,
    subscribed: `\n%c\t%c subscribed:\t${state.counter['subscribe'][eventName] - (state.counter['unsubscribe'][eventName] || 0)}×`,
    callback: `${callbackName ? `\n%c\t%c callback:\t\t${callbackName}` : ''}`,
    data: `\n%c\t%c data:\t\t   `
  }

  const styles = {
    highlight: [
      'font-weight: bold;',
      'font-weight: initial;'
    ],
    block: [
      `background-color: ${stringToHex(eventName)}`,
      'background-color: initial'
    ]
  }

  const messages = {
    'publish': [
      `[publish]${texts.event}${texts.published}${texts.data}`,
      ...[...styles.block, ...styles.highlight, ...styles.block, ...styles.block],
      data
    ],
    'subscribe': [
      `[subscribe]${texts.event}${texts.subscribed}${texts.callback}`,
      ...[...styles.block, ...styles.highlight, ...styles.block, ...(callbackName ? styles.block : [])]
    ],
    'unsubscribe': [
      `[unsubscribe]${texts.event}${texts.subscribed}${texts.callback}`,
      ...[...styles.block, ...styles.highlight, ...styles.block, ...(callbackName ? styles.block : [])]
    ]
  }

  const consoleTypes = {
    'publish': 'info',
    'subscribe': 'warn',
    'unsubscribe': 'error'
  }

  console[consoleTypes[type]](...messages[type])
}

/* --------------------------------- Export --------------------------------- */

export {
  publish,
  subscribe
}
