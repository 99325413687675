import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  proxyName: 'AdminProxy',
  devProxyName: 'DEV AdminProxy',
  faviconId: 'favicon',
  devFavicon: 'favicon/favicon-dev.png'
}

const state = {
  menuPath: null,
  moduleTitle: null
}

/* ----------------------------- Public methods ----------------------------- */

const init = ({ dev }) => {
  if (dev) {
    document.getElementById(conf.faviconId).href = conf.devFavicon
  }
  const proxyName = dev ? conf.devProxyName : conf.proxyName

  document.title = proxyName
  pubsub.subscribe('menuRendered', ({ text }) => menuItemChaged(text, proxyName))
  pubsub.subscribe('content.title', (moduleTitle) => moduleUrlChanged(moduleTitle, proxyName))
  pubsub.subscribe('logout', () => clearTitle(proxyName))
}

const moduleUrlChanged = (moduleTitle, proxyName) => {
  moduleTitle = (moduleTitle || '').trim()

  if (state.moduleTitle === moduleTitle) return
  state.moduleTitle = moduleTitle

  if (moduleTitle) {
    window.document.title = `${moduleTitle} – ${proxyName}`
  } else if (state.menuPath) {
    const path = state.menuPath
      .split('/')
      .slice(1)
      .map(x => x.trim())
      .join(' / ')

    document.title = `${path} – ${proxyName}`
  } else {
    clearTitle(proxyName)
  }
}

const clearTitle = (proxyName) => {
  document.title = proxyName
}

const menuItemChaged = (menuPath, proxyName) => {
  state.menuPath = menuPath
  moduleUrlChanged(state.moduleTitle, proxyName)
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
