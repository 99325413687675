import template from '../../handlebars/notifications.handlebars'
import texts from '../config/texts.js'
import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  defaults: { // TODO: part of old API, remove after some time
    timeout: 10 * 1000, // ms
    type: 'ok'
  },
  timeout: 10 * 1000,
  notificationTypes: ['info', 'ok', 'warning', 'error']
}

const dom = {
  container: document.getElementById('notifications-container'),
  closeAttribute: 'data-notification-close'
}

/* ----------------------------- Public methods ----------------------------- */

const init = () => {
  pubsub.subscribe('postMessage.notification', handleModulesMessage)
  pubsub.subscribe('webSockets.notification', handleModulesMessage)
}

const ok = (message, heading) => {
  create({ message, heading, type: 'ok', timeout: conf.timeout })
}

const info = (message, heading) => {
  create({ message, heading, type: 'info', timeout: conf.timeout })
}

const warning = (message, heading) => {
  create({ message, heading, type: 'warning', timeout: conf.timeout })
}

const error = (message, heading) => {
  create({ message, heading, type: 'error', timeout: false })
}

const create = (settings) => {
  const wrapperEl = document.createElement('div')
  wrapperEl.innerHTML = template({ ...settings, closeAttribute: dom.closeAttribute })
  const notificationEl = wrapperEl.firstElementChild

  const destroy = () => destroyNotification(notificationEl, closeEl, timer, destroy)

  const timer = settings.timeout ? setTimeout(destroy, settings.timeout) : null
  const closeEl = getCloseButton(notificationEl)
  closeEl.addEventListener('click', destroy)

  dom.container.insertBefore(notificationEl, dom.container.firstChild)
}

const createOld = (settings) => { // TODO: part of old API, remove after some time
  settings = {
    ...conf.defaults,
    ...settings
  }
  create(settings)
}

/* ----------------------------- Local methods ------------------------------ */

const getCloseButton = (el) => el.querySelector(`[${dom.closeAttribute}]`)

/* ----------------------------- Pure functions ----------------------------- */

const destroyNotification = (notificationEl, closeEl, timer, clickHandler) => {
  clearTimeout(timer)
  closeEl.removeEventListener('click', clickHandler)
  notificationEl.remove()
}

const handleModulesMessage = ({ data, origin }) => {
  const type = (data.type || '').toLowerCase()
  const message = data.message || ''
  const heading = texts.moduleMessage.heading(origin)

  switch (type) {
    case 'ok': ok(message, heading); break
    case 'info': info(message, heading); break
    case 'warning': warning(message, heading); break
    case 'error': error(message, heading); break
    default: info(message, heading)
  }
}

/* --------------------------------- Export --------------------------------- */

export {
  init,

  info,
  ok,
  warning,
  error,

  createOld as create // TODO: part of old API, remove after some time
}
