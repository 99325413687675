/**
 * Removes leading character if it is present in string at index 0
 * @param  {string} string
 * @param  {character} character to strip from the beggining of string
 * @return {string}
 */
export const stripLeadingCharacter = function (string, character) {
  if (!string) return string
  if (string[0] === character) {
    string = string.substr(1)
  }
  return string
}
