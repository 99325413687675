import texts from '../config/texts.js'
import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  whitelistedMessageTypes: [
    'notification',
    'sidebar',
    'spinner'
  ]
}

/* ----------------------------- Public methods ----------------------------- */

const init = () => {
  window.addEventListener('message', handlePostMessage)
}

const handlePostMessage = (e) => {
  if (!e.data) return

  // ignore messages from different url origin
  const messageOrigin = e.origin || e.originalEvent.origin
  const proxyOrigin = window.location.origin
  const messageFromDifferentOrigin = proxyOrigin !== messageOrigin

  if (messageFromDifferentOrigin) {
    console.warn(texts.proxyWarning.messageFromDifferentOrigin(messageOrigin, proxyOrigin))
    return
  }

  // TODO: should be some router utils function to convert module url to real
  //       module name like we display in menu
  const module = e.source.location.pathname.split('/')[1]
  const messageFromSettingsModule = module === 'proxy'

  const type = e.data.type
  const data = e.data.data || {}
  if (typeof type !== 'string' || !type) return

  const messageTypeIsWhitelisted = conf.whitelistedMessageTypes.includes(type)

  // allow all messages from settings module, from other modules allow only whitelisted ones
  if (messageFromSettingsModule || messageTypeIsWhitelisted) {
    pubsub.publish(`postMessage.${type}`, { data: data, origin: module })
  }
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
