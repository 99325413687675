import * as pubsub from '../utils/pubsub.js'
import * as Sidebar from './sidebar.js'
import ajax from '../utils/ajax.js'

/*
	zmena sirky leveho a praveho sloupce tazenim mysi
*/
export class Resizer {
	constructor() {
		this._dom = {
			container:document.querySelector(".sidebar__resizer"),
			leftCol:document.getElementById("sidebar"),
			rightCol:document.getElementById("proxy-wrapper"),
			frameCover:document.createElement("div"),
			frameBox:document.getElementById("content-overlay-wrapper")
		};
		this._dom.frameCover.style.cssText = "position:absolute;top:0;left:0;z-index:999;width:100%;height:100%;background-color:#fff;opacity:0.1";
		this._startX = 0;
		this._startSize =  parseInt(window.getComputedStyle(this._dom.leftCol,null).width);
	}
	
	handleMessage(evnt) {
		this.handleEvent(evnt.event);
	}
	
	handleEvent(e) {
		let type = e.type;
		e.preventDefault()
		switch(type) {
			case "mousedown":
				// handler na stisknuti mysi (zacatek tazeni)
				this._start(e);
			break;
			case "mousemove":
				// vklastni tazeni
				this._move(e);
			break;
			case "mouseup":
				// konec tazeni
				this._end(e);
			break;
			default:
			break;
		}
	}
	
	init() {
		this._dom.container.addEventListener("mousedown",this, false);
		
	}
	
	_start(e) {
		this._startSize =  parseInt(window.getComputedStyle(this._dom.leftCol,null).width);
		window.top.addEventListener("mousemove",this, false);
		window.top.addEventListener("mouseup",this, false);
		this._startX = e.clientX;
		this._dom.frameBox.appendChild(this._dom.frameCover);
	}
	
	_move(e) {
		//this._e = e
		this._computePosition(e);

	}
	
	_end(e) {
		window.top.removeEventListener("mousemove",this, false);
		window.top.removeEventListener("mouseup",this, false);
		this._computePosition(e);
		this._startX = 0;
		this._dom.frameBox.removeChild(this._dom.frameCover);
		/* ulozim stav */
		Sidebar.setWidth(parseInt(this._dom.leftCol.style.width), true);
	}
	
	/* vypocet sirky */
	_computePosition(e) {
		this._startX = this._startSize;
		var diff = e.clientX - this._startX;
		this._startX = e.clientX
		this._startSize += diff;
		this._startSize = Math.max(this._startSize,Sidebar.MIN_WIDTH); /* nesmi byt mensi nez MIN_WIDTH*/
		this._startSize = Math.min(this._startSize,window.innerWidth*Sidebar.MAX_WIDTH_RATIO); /* nesmi byt vetsi nez vypocteny pomer */
		this._dom.leftCol.style.width = this._startSize + "px";
		this._dom.rightCol.style.paddingLeft = this._startSize + "px";
	}
}