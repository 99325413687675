export default {

  keys: {
    ENTER: 13,
    SPACE: 32,
    ESC: 27,
    TAB: 9,

    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
  },

  mouse: {
    LEFT: 1,
    MIDDLE: 2,
    RIGHT: 3
  },

  http: {
    OK_200: 200,

    BAD_REQUEST_400: 400,
    UNAUTHORIZED_401: 401,
    FORBIDDEN_403: 403,
    NOT_FOUND_404: 404,
    METHOD_NOT_ALLOWED_405: 405,
    REQUEST_TIMEOUT_408: 408,
    CONFLICT_409: 409,

    INTERNAL_SERVER_ERROR_500: 500
  }

}
