export default {

  page: {
    title: 'AdminProxy',
    title404: 'Modul nalezen'
  },

  groupsSelect: {
    favorites: `Oblíbené`,
    others: `Ostatní`,
    all: `Všechny moduly`
  },

  ajax: {
    error: `ajax request error`
  },

  login: {
    serverError: `Bohužel se nám nepodařilo navázat komunikaci se serverem. Zkuste obnovit stránku.`
  },

  logout: {
    text: `Opravdu se chcete odhlásit?`,
    yes: `Ano, odhlásit`,
    no: `Storno`,
    error: `Odhlášení se nezdařilo, prosím zkuste obnovit stránku`
  },

  superlogin: {
    return: `Opravdu chcete ukončit superlogin a vrátit se ke svému původnímu účtu?`
  },

  moduleMessage: {
    heading: (moduleName) => `Zpráva z modulu ${moduleName}`
  },

  modal: {
    yes: `Ano`,
    no: `Storno`
  },

  modalModuleInfo: {
    heading: `Informace a kontakty`,
    close: `Zavřít`
  },

  footer: {
    moduleInfoRequestFailed: (moduleName) => `Nepodařilo se nám získat informace o modulu ${moduleName}, zkuste to prosím později.`
  },

  proxyWarning: {
    prefix: `AdminProxy warning:`,
    websockets: `Cannot establish WebSocket connection. Without this connection proxy is not able to display messages/notifications sent from module's backend (these messages are created by adding "X-Fam-Message" header into document's response). Notifications from module's frontend are send through window.top.postMessage, so these are not affected.`,
    messageFromDifferentOrigin: (messageOrigin, proxyOrigin) => `PostMessage received but message's origin (${messageOrigin}) is not the same as proxy's window.location.origin (${proxyOrigin}). Because of that, message is viewed as unsafe and nothing is going to be done with message's content.`
  },
  
  select: {
  	  shortcuts: {
  	  	  "switch": "Skrýt / zobrazit"
  	  }
  },
  
   sidebar: {
  	  shortcuts: {
  	  	  "switch": "Skrýt / zobrazit"
  	  }
  }

}
