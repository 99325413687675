// TODO: if 'Ostatní' group is empty, do not show it at all
// TODO: bolden groups where currently opened module is present

import texts from '../config/texts.js'
import codes from '../utils/codes.js'
import * as pubsub from '../utils/pubsub.js'
import shortCuts from '../modules/shortcuts.js'

const conf = {
  dom: {
    select: document.getElementById('select'),
    selectToggle: document.getElementById('select-toggle'),
    selectText: document.getElementById('select-text'),
    selectOptions: document.getElementById('select-options'),
    // TODO: since options are only rendered once, we dont need method
    getOptions: () => conf.dom.selectOptions.querySelectorAll(`.${conf.classes.item}`),
    getSelectedOption: () => document.querySelector(`.${conf.classes.itemSelected}`)
  },
  classes: {
    selectOpened: 'select--opened',
    item: 'select__option',
    itemSelected: 'select__option--selected'
  },
  dispatchedEventName: 'selectGroupChange'
}

const state = {
  visible: false,
  selectedItemIndex: null,
  options: []
}

// Main methods

const init = ({ groups, menu }) => {
  state.options = prepareListOfOptions(groups, menu)

  renderOptions(state.options)
  setOptionByIndex(0, false, false)

  conf.dom.selectToggle.addEventListener('click', toggleVisibility)
  pubsub.subscribe('groupChange', ({ groupName }) => {
    let index = state.options.indexOf(groupName)
    if (index === -1) {
      index = state.options.indexOf(texts.groupsSelect.all)
    }
    setOptionByIndex(index, false, true)
  })
  shortCuts.setShortCut({
    type: "keydown",
    key: "w",
    modifyKeys: ["altKey"],
    handler:toggleSelect
  })
  conf.dom.selectToggle.title =  texts.select.shortcuts["switch"] + ": Alt + w"
}

// Local methods

const prepareListOfOptions = (groups, menu) => {
  const visibleModules = Object.keys(menu)
    .filter(key => key !== 'proxy') // settings module is hidden in left menu
    .filter(key => menu[key] && menu[key].menu && menu[key].menu.length > 0)

  const visibleGroups = Object.keys(groups)
    .filter(key => groups[key].some(module => visibleModules.includes(module)))

  const allModulesWithGroup = Object.keys(groups)
    .map(key => groups[key])
    .reduce((p, c) => p.concat(c), [])

  const allModulesAreInGroup = visibleModules.every(x => allModulesWithGroup.includes(x))
  return [
    texts.groupsSelect.favorites,
    ...visibleGroups,
    visibleGroups.length > 0 && !allModulesAreInGroup ? texts.groupsSelect.others : '',
    texts.groupsSelect.all
  ].filter(x => x)
}

const renderOptions = (optionsList) => {
  const html = optionsList.map(group => optionTemplate(group, conf.classes.item)).join('')
  conf.dom.selectOptions.innerHTML = html
}

const setOptionByIndex = (index, closeSelect, dispatchEvent) => {
  if (index === state.selectedItemIndex) {
    if (closeSelect) setOpenedState(false)
    return
  }

  state.selectedItemIndex = index

  const groupName = state.options[state.selectedItemIndex]
  conf.dom.selectText.innerHTML = groupName
  renderHighlightForSelectedOption(state.selectedItemIndex)

  if (closeSelect) setOpenedState(false)
  if (dispatchEvent) pubsub.publish(conf.dispatchedEventName, { groupName })
}

const renderHighlightForSelectedOption = (index) => {
  const className = conf.classes.itemSelected
  const selectedItemEl = conf.dom.getSelectedOption()
  if (selectedItemEl) selectedItemEl.classList.remove(className)
  conf.dom.getOptions()[index].classList.add(className)
}

const toggleVisibility = () => {
  setOpenedState(!state.visible)
}

const setOpenedState = (openSelect) => {
  state.visible = openSelect
  conf.dom.select.classList[openSelect ? 'add' : 'remove'](conf.classes.selectOpened)
  handleEventListeners(openSelect)
}

const toggleSelect = () => {
	setOpenedState(!state.visible)
}

const handleEventListeners = (add) => {
  const listenerAction = `${add ? 'add' : 'remove'}EventListener`

  conf.dom.selectOptions[listenerAction]('click', changeSelectedEl)

  window[listenerAction]('keydown', closeSelectKeys)
  window[listenerAction]('keydown', moveSelectKeys)
  window[listenerAction]('click', windowClick)

  // click inside iframe cannot be caught on window of parent document so we
  // need to attach listener to the iframe's window

  // TODO: should be method from content.js
  const iframe = document.querySelector('iframe')
  try {
    if (iframe) iframe.contentWindow[listenerAction]('click', iframeClick)
  } catch (e) {}
}

const changeSelectedEl = (e) => {
  const target = e.target
  const allOptions = conf.dom.getOptions()

  let index = [...allOptions].indexOf(target)
  if (index === -1) return

  setOptionByIndex(index, true, true)
}

const closeSelectKeys = (e) => {
  const arrowKeycodes = [codes.keys.ESC, codes.keys.SPACE, codes.keys.ENTER]
  if (!arrowKeycodes.includes(e.keyCode)) return

  setOpenedState(false)
}

const moveSelectKeys = (e) => {
  const arrowKeycodes = [codes.keys.UP, codes.keys.DOWN]
  if (!arrowKeycodes.includes(e.keyCode)) return

  const direction = e.keyCode === codes.keys.DOWN ? 1 : -1
  const newIndex = state.selectedItemIndex + direction
  if (newIndex + 1 > state.options.length || newIndex < 0) return

  setOptionByIndex(newIndex, false, true)
}

const iframeClick = () => {
  setOpenedState(false)
}

const windowClick = (e) => {
  const clickInsideSelect = conf.dom.select.contains(e.target)
  if (!clickInsideSelect) setOpenedState(false)
}

const optionTemplate = (text, elClass) => `
  <div class="${elClass}">${text}</div>
`

// Export

export { init }
