var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dev") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <form class=\"login__method-dev\" action=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"url","hash":{},"data":data,"loc":{"start":{"line":18,"column":52},"end":{"line":18,"column":61}}}) : helper)))
    + "\" method=\"post\">\n              <label for=\"dev-username\" class=\"login__method-dev-label\">Developerský login</label>\n              <div class=\"login__method-dev-inputs\">\n                <input\n                  id=\"dev-username\"\n                  class=\"login__method-dev-username\"\n                  type=\"text\"\n                  name=\"username\"\n                  placeholder=\"uživatelské jméno\"\n                  autofocus\n                  required\n                >\n                <input class=\"login__method-dev-submit\" type=\"submit\" value=\"Přihlásit se\">\n              </div>\n            </form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":34,"column":21},"end":{"line":34,"column":30}}}) : helper)))
    + "\" class=\"login__method\">\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":35,"column":14},"end":{"line":35,"column":25}}}) : helper)))
    + "\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"login\">\n\n  <header class=\"login__header\">\n    <a href=\"https://seznam.cz\"><div class=\"login__logo\"></div></a>\n    <a href=\"https://seznam.cz\" class=\"login__seznam-link\">Seznam</a>\n  </header>\n\n  <section class=\"login__content\">\n\n    <section class=\"login__content-left\">\n\n      <h2 class=\"login__heading\">Přihlaste se do AdminProxy</h2>\n\n      <div class=\"login__methods\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginMethods") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n\n    </section>\n\n    <section class=\"login__content-right\">\n      <div class=\"login__computer-illustration\"></div>\n    </section>\n\n  </section>\n\n  <footer class=\"login__footer\">\n    <p class=\"login__footer-paragraph\">Copyright &copy; 1996&ndash;"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":51,"column":67},"end":{"line":51,"column":77}}}) : helper)))
    + " Seznam.cz a.s.</p>\n  </footer>\n\n</div>\n";
},"useData":true});