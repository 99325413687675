import * as pubsub from '../utils/pubsub.js'
import shortCuts from '../modules/shortcuts.js'
import ajax from '../utils/ajax.js'
import texts from '../config/texts.js'

/* -------------------------- Config & local state -------------------------- */

export const MIN_WIDTH = 150;
export const MAX_WIDTH_RATIO = 0.85;

const conf = {
  openedByDefault: true
}

const state = {
  sidebarOpened: false
}

const dom = {
  elements: {
    sidebarEl: document.getElementById('sidebar'),
    sidebarButtonEl: document.getElementById('sidebar-button'),
    proxyWrapperEl: document.getElementById('proxy-wrapper')
  },
  classes: {
    sidebarOpened: 'sidebar--opened',
    proxyShrinked: 'proxy-wrapper--shrinked',
    dev: 'sidebar--dev'
  }
}

var _options = null

/* ----------------------------- Public methods ----------------------------- */

const init = ({ dev, options }) => {
  if (dev) {
    dom.elements.sidebarEl.classList.add(dom.classes.dev)
  }
  _options = options
  dom.elements.sidebarButtonEl.addEventListener('click', toggleSidebar)
  pubsub.subscribe('postMessage.sidebar', handleModulesMessage)
  shortCuts.setShortCut({
    type: "keydown",
    key: "q",
    modifyKeys: ["altKey"],
    handler:toggleSidebar
  })
  dom.elements.sidebarButtonEl.title = texts.select.shortcuts["switch"] + ": Alt + q"
  setOpenedState(_options.sidebarEnabled)
}

/* ----------------------------- Local methods ------------------------------ */
/* finalni nastaveni sirky */
export const setWidth = (width, save) => {
	let w = Math.max(width,MIN_WIDTH)
	w = Math.min(w,window.innerWidth*MAX_WIDTH_RATIO)
	
	dom.elements.sidebarEl.style.width = w + "px"
	dom.elements.proxyWrapperEl.style.paddingLeft = w + "px"
	_options.sidebarWidth = width
	if(save) {
		var uid = window.App.State.userData.uid
		ajax({
			method: 'PUT',
			url: `/ajax/users/${encodeURIComponent(uid)}`,
			data: { sidebarWidth: _options.sidebarWidth}
		})
	}
}

const setOpenedState = (openSidebar) => {
  state.sidebarOpened = openSidebar

  const classListAction = openSidebar ? 'add' : 'remove'
  dom.elements.sidebarEl.classList[classListAction](dom.classes.sidebarOpened)
  dom.elements.proxyWrapperEl.classList[classListAction](dom.classes.proxyShrinked)
  if(openSidebar) {
     setWidth(_options.sidebarWidth)
  	  dom.elements.proxyWrapperEl.style.paddingLeft = _options.sidebarWidth + "px"
  } else {

  	  dom.elements.proxyWrapperEl.style.paddingLeft = ""
  }
  saveSidebarState(openSidebar)
}

/* ulozim stav leveho sloupce */
const saveSidebarState = (state) => {
  var uid = window.App.State.userData.uid
  _options.sidebarEnabled = state
  return ajax({
    method: 'PUT',
    url: `/ajax/users/${encodeURIComponent(uid)}`,
    data: { sidebarEnabled: state }
  })
}

const toggleSidebar = () => {
  setOpenedState(!state.sidebarOpened)
}

const handleModulesMessage = (message) => {
  switch (message.data.action) {
    case 'close':
      setOpenedState(false)
      break
    case 'open':
      setOpenedState(true)
      break
    case 'toggle':
      setOpenedState(!state.sidebarOpened)
      break
  }
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
