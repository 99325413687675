import ajax from '../utils/ajax.js'

import * as Notifications from '../ui/notifications.js'
import * as Modal from '../ui/modal.js'

const init = () => {
  window._proxyBridge = {
    Proxy: window.App,
    ajax: ajax,
    getUserData: () => window.App.State.userData,
    Notifications,
    Modal
  }
}

export { init }
