import ajax from '../utils/ajax.js'
import * as pubsub from '../utils/pubsub.js'

import * as Router from './router.js'
import * as Menu from './menu.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  urlChangeInterval: 100, // how often do we detect if url changed in iframe (milliseconds)
  handleIframeLinkClicks: true
}

const state = {
  timer: null,
  currentDocument: null,
  historyLength: null
}

const dom = {
  getContent: () => document.getElementById('content'),
  getIframe: () => dom.getContent().querySelector('iframe'),
  getContentWrapper: () => document.getElementById('content-wrapper'),
  iframeClass: 'content__iframe'
}


/* ----------------------------- Public methods ----------------------------- */

const init = (url) => {
  render(url)

  pubsub.subscribe('ajaxNotAuthorized', destroy)
  pubsub.subscribe('url.change', ({ url }) => render(url))
  pubsub.subscribe('menu.clickOnLinkWithHrefSameAsCurrentUrl', ({ url }) => render(url))
  pubsub.subscribe('router.redirectToDefaultModule', ({ url }) => render(url))
}

const render = (url) => {
  if (url === '/') return

  displayContent(url, conf.urlChangeInterval)
  saveUrlToDb(window.App.State.userData.uid, window.location.hash)
}

const destroy = () => {
  clearInterval(state.iframeInterval)

  const iframe = dom.getIframe()
  if (!iframe) return

  pubsub.publish('content.destroy', { iframe })
  try {
    iframe.contentWindow.removeEventListener('DOMContentLoaded', domContentLoadedEvent)
    iframe.contentWindow.removeEventListener('beforeunload', beforeUnloadEvent)
    if (conf.handleIframeLinkClicks) {
      iframe.contentWindow.removeEventListener('mousedown', handleIframeMouseDown, true)
    }
  } catch (e) {}
  iframe.remove()
}

/* ----------------------------- Local methods ------------------------------ */

const displayContent = (url, urlChangeInterval) => {
  destroy()

  const iframe = document.createElement('iframe')
  iframe.src = url
  iframe.classList.add(dom.iframeClass)

  dom.getContent().appendChild(iframe)

  // window   - iframe.contentWindow || iframe
  // document - iframe.contentDocument || iframe.contentWindow.document
  let iframeWindow = null
  try {
    iframeWindow = iframe.contentWindow
  } catch (e) {
    return
  }
  state.currentDocument = iframeWindow.document
  addEventListeners(iframeWindow)
  handleIframeUrlChange(iframeWindow)

  state.iframeInterval = setInterval(() => {
    try {
      handleIframeUrlChange(iframeWindow)
      // consider putting it outside of loop and use DOM contentLoaded?
      pubsub.publish('content.title', iframeWindow.document.title)
    } catch (e) {}
  }, urlChangeInterval)

  pubsub.publish('content.display', { iframe })
}

const domContentLoadedEvent = () => {
  pubsub.publish('content.loadEventFired')
}

const beforeUnloadEvent = () => {
  pubsub.publish('content.beforeUnloadFired')
}

const addEventListeners = (iframeWindow) => {
	
  try {
    iframeWindow.addEventListener('DOMContentLoaded', domContentLoadedEvent)
    iframeWindow.addEventListener('keydown', keydownHandler)
    iframeWindow.addEventListener('beforeunload', beforeUnloadEvent)
    if (conf.handleIframeLinkClicks) {
      iframeWindow.addEventListener('mousedown', handleIframeMouseDown, true)
    }
  } catch (e) {}
}

const handleIframeUrlChange = (iframeWindow) => {
  let iframeUrl = ''
  try {
    // accessing iframe's content causes error if it is from different domain
    iframeUrl = iframeWindow.location.href
    if (iframeUrl === 'about:blank') {
      iframeUrl = ''
    }
  } catch (e) {}

  if (state.currentDocument !== iframeWindow.document) {
    state.currentDocument = iframeWindow.document
    addEventListeners(iframeWindow)
  }

  const parser = document.createElement('a')
  parser.href = iframeUrl

  const { protocol, hostname, port, pathname, search, hash } = parser
  if (pathname === '/' && !search && hash.startsWith('#/')) {
    const moduleUrl = hash.replace(/^#/, '')
    iframeUrl = `${protocol}//${hostname}:${port}${moduleUrl}`
    displayContent(iframeUrl, conf.urlChangeInterval)
    return
  }

  const module = Router.getModuleFromUrl(iframeUrl)
  if (!module) return

  // error pages should not update url or menu
  if (module.name.startsWith('templ/')) return

  if (window.history.length !== state.historyLength) {
    state.historyLength = window.history.length
    pubsub.publish('urlChange', { module })
    return
  }
  state.historyLength = window.history.length

  // we are replacing because iframe redirect is already changing then browser history
  const urlChanged = Router.historyReplaceState(module.url) // update url
  if (!urlChanged) return

  pubsub.publish('content.urlChanged')
  saveUrlToDb(window.App.State.userData.uid, window.location.hash)
}

const handleIframeMouseDown = (e) => {
  if (!Menu.isLinksFixingEnabled()) return

  const { target } = e

  if (target.tagName !== 'A') return

  const url = target.getAttribute('href')
  if (!url) return
  if (!url.startsWith('/')) return
  if (url.startsWith('/#')) return

  target.href = '/#' + url
}

/* posluchac na stisk klavesy pro shortuts.js*/
const keydownHandler = (e) => {
	pubsub.publish("content.keydown",{event:e})
}

const saveUrlToDb = (uid, url) => {
  url = url.substr(2) // remove '#/' from the beginning of the string

  // TODO: put this url into config?
  if (url === 'html,chromewebdata') return

  return ajax({
    method: 'PUT',
    url: `/ajax/users/${encodeURIComponent(uid)}`,
    data: { lastVisitedPage: url }
  })
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
