import './namespace.js'
import './state.js'

import * as url from 'url'

// conf
import texts from './config/texts.js'

// utils
import * as ajax from './utils/ajax-methods.js'
import codes from './utils/codes.js'
import * as pubsub from './utils/pubsub.js'

// ui
import * as Notifications from './ui/notifications.js'

// modules
import * as Content from './modules/content.js'
import * as ContentLoadingSpinner from './modules/content-loading-spinner.js'
import * as ErrorLog from './modules/error-log.js'
import * as Footer from './modules/footer.js'
import * as IframeBridge from './modules/iframe-bridge.js'
import * as Login from './modules/login.js'
import * as LogoutTimer from './modules/logout-timer.js'
import * as MenuTop from './modules/menu-top.js'
import * as Menu from './modules/menu.js'
import * as PostMessage from './modules/post-message.js'
import * as Router from './modules/router.js'
import * as Select from './modules/select.js'
import * as Sidebar from './modules/sidebar.js'
import * as ScrollShadow from './modules/scroll-shadow.js'
import * as UrlBeforeLoginRedirect from './modules/url-before-login-redirect.js'
import * as Views from './modules/views.js'
import * as WebSocketConnection from './modules/web-sockets.js'
import * as Title from './modules/title.js'
import {Resizer} from './modules/sidebar-resizer.js'

const config = {
  // TODO: duplicate with app.js
  // if there is no module in url, display this
  defaultModuleUrl: '/proxy/my-settings.html'
}

// Main method

const init = () => {
  ErrorLog.init()

  const { query } = url.parse(window.location.href, true)
  if (query && query.next) {
    // backend cannot redirect directly to proxy+module url because hashes are
    // not accesible on backend. So it instead includes next query parameter
    // which we convert to proxy url: /?next=/test/a -> /#/test/a
    const newUrl = query.next
    window.history.replaceState(null, null, '/#' + newUrl)
  }

  Login.init()

  loginAndRoute() // async
}

// Local methods

const loginAndRoute = async () => {
  let response
  try {
    response = await ajax.login()
  } catch (e) {
    // TODO: display whole error page instead of notifications?
    window.App.State.loggedIn = false
    Notifications.error(texts.login.serverError)
    return
  }

  if (response.status === codes.http.OK_200) {
    loginSuccesful(response.data)
  } else if (response.status === codes.http.UNAUTHORIZED_401) {
    const url = '/ajax/login'
    notAuthorized({ statusText: response.statusText, url })
  } else {
    // TODO: handle
  }
}

const loginSuccesful = (data) => {
  // if we have saved url (before we were redirected to login service), restore this url
  UrlBeforeLoginRedirect.restore()

  // save user data
  window.App.State.userData = { // TODO: do not use global state
    uid: data.uid,
    realUid: data.realUid || false,
    name: data.name,
    root: data.root,
    csrf: data.xsrf, // TODO: unused?
    savedData: {
      visibleUsers: data.visibleUsers,
      visibleGroups: data.visibleGroups
    }
  }

  const settingsUrls = data.menu.proxy
    ? data.menu.proxy.menu[0].menu
      .map(x => x.menu ? x.menu : x)
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.url)
    : []
  window.App.State.settingsUrls = settingsUrls

  pubsub.publish('userLoggedIn', { uid: window.App.State.userData.uid })
  var contentUrl = getModuleFromURLandRenderMenu(false, data.lastVisitedPage)
  Router.historyPushState(contentUrl)

  PostMessage.init()
  WebSocketConnection.init()
  ScrollShadow.init()
  Title.init({
    dev: data.dev
  })
  ContentLoadingSpinner.init(contentUrl)

  Content.init(contentUrl)

  Views.switchToProxy()
  window.App.State.loggedIn = true
  LogoutTimer.init({ logoutInterval: data.logoutTimer })

  window.addEventListener('hashchange', (e) => {
    // TODO: maybe move this inside router module?
    if (!window.App.State.loggedIn) {
      // TODO: this should be inside UrlBeforeLoginRedirect module
      UrlBeforeLoginRedirect.save()
      return
    }

    getModuleFromURLandRenderMenu(true)

    const module = Router.getModuleFromUrl(window.location.href)
    // TODO: we are doing this because historyReplaceState is dispatching
    // url changed event to which other modules are listening. check if
    // historyReplaceState does not cause some problems with browsers history
    Router.historyReplaceState(module.url)
  }, false)

  Notifications.init()
  Router.init()
  let sidebarWidth = "sidebarWidth" in data ? data.sidebarWidth : 290;
  let sidebarEnabled = "sidebarEnabled" in data ? data.sidebarEnabled : true;
  Sidebar.init({
    dev: data.dev,
    options: {
    	sidebarEnabled:sidebarEnabled,
    	sidebarWidth: sidebarWidth
    }
  })

  Select.init({
    groups: data.menuGroups,
    menu: data.menu
  })

  MenuTop.init({
    uid: data.uid,
    realUid: data.realUid,
    name: data.name,
    root: data.root,
    showSettingsButton: settingsUrls.length > 1,
    dev: data.dev
  })

  // TODO: enforce on backend forbidden group names ('Oblíbené' and 'Ostatní')
  Menu.init({
    menu: data.menu,
    groups: data.menuGroups,
    favorites: data.favorites,
    uid: data.uid
  })

  Footer.init({
    root: data.root
  })

  IframeBridge.init()

  // resize sidebaru mysi
  new Resizer().init()

  pubsub.subscribe('ajaxNotAuthorized', notAuthorized)
  pubsub.subscribe('logoutClick', loginAndRoute)
}

const notAuthorized = async ({ statusText, url }) => {
  if (!(url === '/ajax/login' && statusText === 'Unauthorized')) {
    Notifications.warning(statusText)
  }

  window.App.State.loggedIn = false

  // TODO: logout event is dispatched twice!
  pubsub.publish('logout')

  // save url to local storage so we can restore it after we are redirected back from login service
  UrlBeforeLoginRedirect.save()
}

const getModuleFromURLandRenderMenu = (renderContent, lastVisitedPage) => {
  var module = Router.getModuleFromUrl(window.location.href)

  if (!module) {
    // if there is no module in url set url to last visited page
    if (lastVisitedPage) {
      Router.historyReplaceState(lastVisitedPage)
    } else {
      // if there is no last visited page saved, display default module
      Router.historyReplaceState(config.defaultModuleUrl)
    }
    module = Router.getModuleFromUrl(window.location.href)
  }

  if (renderContent && module) {
    pubsub.publish('url.change', { url: module.url })
  }

  return module ? module.url : false
}

// Start the app

init()
