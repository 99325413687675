import * as LocalStorage from '../utils/local-storage.js'
import * as Router from '../modules/router.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  localStorageKey: 'login-hash'
}

/* ----------------------------- Public methods ----------------------------- */

const save = () => {
  var hash = window.location.hash
  if (!hash) return

  LocalStorage.set(conf.localStorageKey, hash)
}

const restore = () => {
  var hash = LocalStorage.get(conf.localStorageKey)
  if (!hash) return

  Router.historyReplaceState(hash)
  LocalStorage.remove(conf.localStorageKey)
}

export {
  save,
  restore
}
