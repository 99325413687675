import * as pubsub from '../utils/pubsub.js'
import * as lib from '../utils/lib.js'

let lastUrl = null

const config = {
  // TODO: duplicate with app.js
  // if there is no module in url, display this
  defaultModuleUrl: '/proxy/my-settings.html'
}

const init = () => {
  const url = getModuleFromUrl(window.location.href).url
  if (!url) return
  lastUrl = lib.stripLeadingCharacter(url, '/')
}

const getModuleFromUrl = (url) => {
  if (!url) return false

  const module = url.match(/^.*?[^/]\/([^/#][^#].*?)([?#].*)?$/)

  if (!module) return false

  const match = {
    url: module[1] + (module[2] ? module[2] : ''),
    name: lib.stripLeadingCharacter(module[1], '/'),
    query: module[2] ? module[2] : false
  }

  return match
}

const historyPushState = (url) => {
  return history(url, 'push')
}

const historyReplaceState = (url) => {
  return history(url, 'replace')
}

const redirectToDefaultModule = () => {
  historyReplaceState(config.defaultModuleUrl)
  var module = getModuleFromUrl(window.location.href)

  pubsub.publish('router.redirectToDefaultModule', { url: module.url })
}

const redirectToModuleWithoutProxy = () => {
  const url = window.location.hash.substring(1);
  if (!url) return;
  const target = new URL(url, window.location.origin);
  if (window.location.origin !== target.origin) return; // redirekt na cizí doménu zablokujeme (SRCH-17888 adminproxy - security - je třeba kontrolovat cíl redirektu)
  document.location.replace(url);
}

const history = (url, stateType) => {
  url = lib.stripLeadingCharacter(url, '#')
  url = lib.stripLeadingCharacter(url, '/')

  if (url === lastUrl) {
    return false
  }
  lastUrl = url

  window.history[stateType + 'State'](null, null, '/#/' + url)
  pubsub.publish('urlChange', { module: getModuleFromUrl(window.location.href) })

  return true
}

const getCurrentModuleName = () => {
  // TODO: only temporary until we have mappings for module id -> module name
  return getCurrentModuleId()
}

const getModuleIdFromHash = (url) => {
  if (!url) return false
  return url.split('/')[1] || false
}

const getCurrentModuleId = () => {
  // TODO: only temporary until we have mappings for module id -> module name
  return getModuleIdFromHash(window.location.hash)
}

export {
  init,
  getModuleFromUrl,
  getCurrentModuleName,
  getCurrentModuleId,
  getModuleIdFromHash,
  historyPushState,
  historyReplaceState,
  redirectToDefaultModule,
  redirectToModuleWithoutProxy
}
