import * as pubsub from '../utils/pubsub.js'
import * as Router from '../modules/router.js'

/* -------------------------- Config & local state -------------------------- */
let wsReqMsgLoop
let connection

const conf = {
  url: '/websocket',
  retryIntervalMs: 2000, // when connection closes, try to open it again in x ms
  maximumNumberOfRetries: 5
}

const state = {
  numberOfRetries: 0
}

/* ----------------------------- Public methods ----------------------------- */

const init = () => {
  openConnection()
}

/* ----------------------------- Local methods ------------------------------ */

const openConnection = () => {
  const protocol = window.location.href.startsWith('https:') ? 'wss:' : 'ws:'
  const domain = window.location.host
  const url = `${protocol}//${domain}${conf.url}`

  connection = new window.WebSocket(url)
  connection.onmessage = onMessage
  connection.onopen = onOpen
  connection.onclose = onClose
}

const sendMessage = () => {
  connection.send("Hello backend, do you have a message ?")
}

const onOpen = () => {
  state.numberOfRetries = 0
  wsReqMsgLoop = setInterval(sendMessage, 1000)
}

const onMessage = ({ data }) => {
  const origin = Router.getCurrentModuleName()
  // data from web sockets will come as string, we try to parse them and
  // if they are not a JSON, we will threat them as the message itself
  if (data === "no message :/") {
      return
  }
  try {
    data = JSON.parse(data)
  } catch (e) {
    data = { message: data }
  }

  pubsub.publish('webSockets.notification', { data, origin })
}

const onClose = () => {
  clearInterval(wsReqMsgLoop)
  state.numberOfRetries++
  if (state.numberOfRetries >= conf.maximumNumberOfRetries) {
    return
  }

  setTimeout(openConnection, conf.retryIntervalMs)
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
