export function compareWithDiacritics (strA, strB) {
  if (strA.localeCompare) {
    // if browser supports String.prototype.localeCompare properly sort strings with diacritics
    let currentIndex = 0

    let currentCharA = strA[currentIndex]
    let currentCharB = strB[currentIndex]
    while (currentCharA && currentCharB) {
      const compare = currentCharA.localeCompare(currentCharB)
      if (compare >= 1) {
        return 1
      } else if (compare <= -1) {
        return -1
      }

      currentIndex++
      currentCharA = strA[currentIndex]
      currentCharB = strB[currentIndex]
    }

    return 0
  } else {
    // regular sort
    if (strA > strB) {
      return 1
    } else if (strA < strB) {
      return -1
    } else {
      return 0
    }
  }
}
