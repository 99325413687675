import footerTemplate from '../../handlebars/footer.handlebars'

/* -------------------------- Config & local state -------------------------- */

const dom = {
  footer: document.getElementById('footer'),
}

/* ----------------------------- Public methods ----------------------------- */

const init = ({ root }) => {
  const year = new Date().getFullYear()
  const html = footerTemplate({ year, root })
  dom.footer.innerHTML = html
}

/* ----------------------------- Local methods ------------------------------ */


/* ----------------------------- Pure functions ----------------------------- */

const addLeadingZero = (x) => {
  x = String(x)
  while (x.length < 2) x = `0${x}`
  return x
}

const formatDate = (dateString) => {
  if (!dateString) return ''

  const date = new Date(dateString)
  return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()} ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}:${addLeadingZero(date.getSeconds())}`
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
