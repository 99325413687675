var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal__dialog\">\n  <p class=\"modal__text\" id=\"modal-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":51}}}) : helper)))
    + "</p>\n  <div class=\"modal__buttons-wrapper\">\n    <button id=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"ids") : depth0)) != null ? lookupProperty(stack1,"yesButton") : stack1), depth0))
    + "\" type=\"button\" class=\"modal__button\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"yesCaption") || (depth0 != null ? lookupProperty(depth0,"yesCaption") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yesCaption","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":89}}}) : helper)))
    + "</button>\n    <button id=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"ids") : depth0)) != null ? lookupProperty(stack1,"noButton") : stack1), depth0))
    + "\" type=\"button\" class=\"modal__button\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"noCaption") || (depth0 != null ? lookupProperty(depth0,"noCaption") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"noCaption","hash":{},"data":data,"loc":{"start":{"line":5,"column":72},"end":{"line":5,"column":87}}}) : helper)))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});