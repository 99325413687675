window.App.State = (function () {
  'use strict'

  return {
    userData: {},
    loggedIn: false,
    menuData: {}, // complete data about all submenus
    menuComputed: {}, // contains menu rows which will be rendered (computed subset of menuData)
    currentModule: {},
    selectedMenuItems: [0]
  }
}())
