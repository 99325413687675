import * as ajax from '../utils/ajax-methods.js'
import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  maxLoggedErrors: 10
}

const state = {
  errorsLogged: 0,
  data: null
}

/* ----------------------------- Public methods ----------------------------- */

const init = (config) => {
  window.addEventListener('error', logError)
  pubsub.subscribe('userLoggedIn', addData)
}

/* ----------------------------- Local methods ------------------------------ */

const logError = (e) => {
  if (state.errorsLogged >= conf.maxLoggedErrors) return
  state.errorsLogged++

  const requestData = {
    message: e.message,
    filename: e.filename,
    lineNumber: e.lineno,
    columnNumber: e.colno,
    ...(e.error ? { callstack: e.error.stack } : null),
    ...(state.data ? { data: state.data } : null)
  }

  ajax.logError(requestData)
}

const addData = (data) => {
  state.data = { ...state.data, ...data }
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
