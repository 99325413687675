import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

const conf = {
  showDelay: 1000
}

const state = {
  loadingStartTime: null,
  elapsedTimeInterval: null,
  delayedShowTimeout: null
}

const dom = {
  overlayWrapper: document.getElementById('content-overlay-wrapper'),
  timeElapsed: document.getElementById('loading-elapsed'),
  className: 'content--loading'
}

/* ----------------------------- Public methods ----------------------------- */

const init = () => {
	
  pubsub.subscribe('postMessage.spinner', handleModuleMessage)
  pubsub.subscribe('show.spinner', showSpinner)

  pubsub.subscribe('hide.spinner', hideSpinner)
  dom.overlayWrapper.addEventListener('click', hideSpinner)
}

const showSpinner = () => {
  if(dom.overlayWrapper.classList.contains(dom.className)) {
    return;
  }
  const updateTimeElapsed = () => {
    const duration = Math.round((Date.now() - state.loadingStartTime + conf.showDelay) / 1000)

    // TODO: share these functions with logout-timer.js
    const seconds = duration % 60
    const minutes = Math.floor(duration / 60) % 3600
    const addLeadingZero = (number) => number >= 10 ? number : '0' + number
    dom.timeElapsed.innerHTML = `${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`
  }

  cleanup()

  state.delayedShowTimeout = window.setTimeout(() => {
    state.loadingStartTime = Date.now()
    updateTimeElapsed()
    state.elapsedTimeInterval = window.clearInterval(updateTimeElapsed)
    state.elapsedTimeInterval = window.setInterval(updateTimeElapsed, 1000)

    setSpinnerClass(true)
  }, conf.showDelay)
}

const hideSpinner = () => {
  cleanup()
  setSpinnerClass(false)
}

const cleanup = () => {
  window.clearInterval(state.elapsedTimeInterval)
  window.clearTimeout(state.delayedShowTimeout)
}

const setSpinnerClass = (visible) => {
  dom.overlayWrapper.classList[visible ? 'add' : 'remove'](dom.className)
}

const handleModuleMessage = (evnt) => {
	var type = evnt.data.action
	if(type == "show") {
		showSpinner()
	} else {
		hideSpinner()
	}
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
