/* ----------------------------- Public methods ----------------------------- */

const get = (keyName) => {
  return JSON.parse(window.localStorage.getItem(keyName))
}

const set = (keyName, value) => {
  window.localStorage.setItem(keyName, JSON.stringify(value))
}

const remove = (keyName) => {
  window.localStorage.removeItem(keyName)
}

const clearAll = () => {
  window.localStorage.clear()
}

/* --------------------------------- Export --------------------------------- */

export {
  get,
  set,
  remove,
  clearAll
}
