import template from '../../handlebars/modal.handlebars'

import texts from '../config/texts.js'
import codes from '../utils/codes.js'
import * as views from '../modules/views.js'

/* -------------------------- Config & local state -------------------------- */

const state = {
  promiseResolveFn: null,
  modalVisible: false,
  focusedButton: null
}

const dom = {
  modal: document.getElementById('modal'),
  getYesButton: () => document.getElementById(dom.ids.yesButton),
  getNoButton: () => document.getElementById(dom.ids.noButton),
  classes: {
    modalVisible: 'modal--visible'
  },
  ids: {
    yesButton: 'modal-yes',
    noButton: 'modal-no'
  }
}

/* ----------------------------- Public methods ----------------------------- */

const show = ({ text, yesCaption = texts.modal.yes, noCaption = texts.modal.no }) => {
  if (state.modalVisible) hide()

  dom.modal.classList.add(dom.classes.modalVisible)
  views.blur(true)

  dom.modal.innerHTML = template({ text, yesCaption, noCaption, ids: dom.ids })
  dom.getYesButton().focus()

  state.focusedButton = dom.getYesButton()
  state.modalVisible = true

  handleEventListeners(true)

  return new Promise((resolve) => {
    state.promiseResolveFn = resolve
  })
}

const hide = () => {
  handleEventListeners(false)
  state.modalVisible = false

  dom.modal.classList.remove(dom.classes.modalVisible)
  views.blur(false)
}

/* ----------------------------- Local methods ------------------------------ */

const handleEventListeners = (add) => {
  const listenerAction = `${add ? 'add' : 'remove'}EventListener`

  dom.getYesButton()[listenerAction]('click', yes)
  dom.getNoButton()[listenerAction]('click', no)
  window[listenerAction]('keydown', esc)
  window[listenerAction]('keydown', controls)
}

const resolveForm = (response) => {
  hide()
  state.promiseResolveFn(response)
}

const yes = () => { resolveForm(true) }

const no = () => { resolveForm(false) }

const esc = (e) => {
  if (e.keyCode !== codes.keys.ESC) return
  resolveForm(false)
}

const controls = (e) => {
  const acceptedKeys = [codes.keys.TAB, codes.keys.LEFT, codes.keys.RIGHT, codes.keys.UP, codes.keys.DOWN]
  if (!acceptedKeys.includes(e.keyCode)) return
  e.preventDefault()

  const nextButton = state.focusedButton === dom.getYesButton() ? dom.getNoButton() : dom.getYesButton()
  nextButton.focus()
  state.focusedButton = nextButton
}

/* --------------------------------- Export --------------------------------- */
// TODO: export functions
export {
  show,
  hide
}
