import * as pubsub from '../utils/pubsub.js'

/* -------------------------- Config & local state -------------------------- */

let conf = {
  logoutInterval: 60 * 60, // default logout interval in seconds
  fadeoutThreshold: 5 * 60
}

const state = {
  logoutTimer: null
}

const dom = {
  elements: {
    timer: document.getElementById('logout-timer'),
    overlay: document.getElementById('logout-timer-overlay')
  },
  classes: {
    fadedOut: 'menu-top__logout-timer--faded-out'
  }
}

/* ----------------------------- Public methods ----------------------------- */

const init = (config) => {
  conf = { ...conf, ...config }
  restart()

  pubsub.subscribe('ajax', restart)
  pubsub.subscribe('content.display', restart)
  pubsub.subscribe('postMessage.logoutIntervalChange', ({ data }) => update(data.newInterval))
}

/* ----------------------------- Local methods ------------------------------ */

const restart = () => {
  clearInterval(state.logoutTimer)

  const startTime = Date.now()
  tick(startTime)
  state.logoutTimer = setInterval(() => { tick(startTime) }, 1000)
}

const update = (newLogoutInterval) => {
  conf.logoutInterval = newLogoutInterval
  restart()
}

const tick = (start) => {
  var timeLeft = conf.logoutInterval - Math.floor((Date.now() - start) / 1000)
  if (timeLeft <= 0) {
    showOverlay()
    return
  }

  dom.elements.timer.innerHTML = formatTime(timeLeft)
  dom.elements.timer.classList[(timeLeft > conf.fadeoutThreshold) ? 'add' : 'remove'](dom.classes.fadedOut)
}

const showOverlay = () => {
  clearInterval(state.logoutTimer)
  dom.elements.timer.innerHTML = '00:00'
  dom.elements.overlay.style.display = 'block'
}

/* ----------------------------- Pure functions ----------------------------- */

const formatTime = (timeLeft) => {
  const seconds = timeLeft % 60
  const minutes = Math.floor(timeLeft / 60) % 3600
  const hours = Math.round(timeLeft / 3600)
  const days = Math.round(hours / 24)

  let postfix = ''

  if (timeLeft <= 5 * 60) {
    const paddedSeconds = `${seconds < 10 ? '0' : ''}${seconds}`

    return `${minutes}:${paddedSeconds}`
  } else if (timeLeft <= 60 * 60) {
    if (minutes === 1) postfix = 'minuta'
    else if (minutes >= 2 && minutes <= 4) postfix = 'minuty'
    else if (minutes >= 5) postfix = 'minut'

    return `${minutes} ${postfix}`
  } else if (timeLeft <= 60 * 60 * 24) {
    if (hours === 1) postfix = 'hodina'
    else if (hours >= 2 && hours <= 4) postfix = 'hodiny'
    else if (hours >= 5) postfix = 'hodin'

    return `${hours} ${postfix}`
  } else {
    if (days === 1) postfix = 'den'
    else if (days >= 2 && days <= 4) postfix = 'dny'
    else if (days >= 5) postfix = 'dní'

    return `${days} ${postfix}`
  }
}

/* --------------------------------- Export --------------------------------- */

export {
  init
}
