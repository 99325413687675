import usernameTempl from '../../handlebars/menu-top/username.handlebars'
import bubbleTempl from '../../handlebars/menu-top/bubble.handlebars'

import texts from '../config/texts.js'
import codes from '../utils/codes.js'
import * as ajax from '../utils/ajax-methods.js'
import * as pubsub from '../utils/pubsub.js'
import * as Modal from '../ui/modal.js'
import * as Notifications from '../ui/notifications.js'

const conf = {
  dom: {
    menuTop: document.getElementById('menu-top'),
    usernameWrapper: document.getElementById('username-wrapper'),
    username: document.getElementById('username'),
    menuBubble: document.getElementById('menu-top-bubble'),
    getLogout: () => document.getElementById('logout'),
    getSuperLoginCancel: () => document.getElementById('superlogin-cancel'),
    currentModule: document.getElementById('current-module')
  },
  classes: {
    menuBubbleVisible: 'menu-top__bubble--visible',
    dev: 'menu-top--dev'
  }
}

const state = {
  bubbleOpened: false
}

// Main method

const init = ({ uid, realUid, root, name, showSettingsButton, dev }) => {
  if (!showSettingsButton) {
    document.getElementById('settings-button').style.display = 'none'
  }

  const superLogin = Boolean(realUid)

  conf.dom.username.innerHTML = usernameTempl({ uid, superLogin, realUid, name, root })
  conf.dom.menuBubble.innerHTML = bubbleTempl({ superLogin })

  conf.dom.usernameWrapper.addEventListener('click', () => { setBubbleOpenedState(!state.bubbleOpened) })
  conf.dom.getLogout().addEventListener('click', logout)

  if (superLogin) {
    conf.dom.getSuperLoginCancel().addEventListener('click', superLoginCancel)
  }

  if (dev) {
    conf.dom.menuTop.classList.add(conf.classes.dev)
  }

  pubsub.subscribe('menuRendered', ({ text }) => {
    conf.dom.currentModule.innerHTML = text
  })
}

// Local methods

const handleBubbleEventListeners = (add) => {
  const listenerAction = `${add ? 'add' : 'remove'}EventListener`

  window[listenerAction]('click', hideBubbleClick)
  window[listenerAction]('keydown', hideBubbleKeayboard)

  // click inside iframe cannot be caught on window of parent document so we
  // need to attach listener to the iframe's window
  // TODO: should be method from content.js
  const iframe = document.querySelector('iframe')
  try {
    if (iframe) iframe.contentWindow[listenerAction]('click', iframeClick)
  } catch (e) {}
}

const setBubbleOpenedState = (open) => {
  conf.dom.menuBubble.classList[open ? 'add' : 'remove'](conf.classes.menuBubbleVisible)
  handleBubbleEventListeners(open)
  state.bubbleOpened = open
}

const iframeClick = () => {
  setBubbleOpenedState(false)
}

const hideBubbleClick = ({ target }) => {
  if (conf.dom.usernameWrapper.contains(target)) return

  setBubbleOpenedState(false)
}

const hideBubbleKeayboard = (e) => {
  const arrowKeycodes = [codes.keys.ESC]
  if (!arrowKeycodes.includes(e.keyCode)) return

  setBubbleOpenedState(false)
}

const logout = async () => {
  const clickedYes = await Modal.show({ text: texts.logout.text, yesCaption: texts.logout.yes, noCaption: texts.logout.no })
  if (!clickedYes) return

  const response = await ajax.logout()
  if (response.status !== 200) {
    Notifications.error(texts.logout.error)
    return
  }

  window.history.pushState(null, null, '/')
  pubsub.publish('logoutClick')
}

const superLoginCancel = async () => {
  var clickedYes = await Modal.show({ text: texts.superlogin.return })
  if (!clickedYes) return

  const res = await ajax.superLoginCancel()
  if (res.status === 200) {
    window.location.reload()
  } else {
    Notifications.error(res.statusText)
  }
}

// Export

export {
  init
}
