/* -------------------------- Config & local state -------------------------- */

const dom = {
  elements: {
    viewsWrapper: document.getElementById('views-wrapper'),
    views: {
      proxy: document.getElementById('proxy-page'),
      login: document.getElementById('login-page')
    }
  },
  classes: {
    viewsBlurred: 'views-wrapper--blurred'
  }
}

/* ----------------------------- Public methods ----------------------------- */

const switchToProxy = () => {
  hideAllViews(dom.elements.views)
  show(dom.elements.views.proxy)
}

const switchToLogin = () => {
  hideAllViews(dom.elements.views)
  show(dom.elements.views.login)
}

const blur = (add) => {
  dom.elements.viewsWrapper.classList[add ? 'add' : 'remove'](dom.classes.viewsBlurred)
}

/* ----------------------------- Pure functions ----------------------------- */

const hideAllViews = views => Object.values(views).forEach(el => hide(el))

const hide = el => { el.style.display = 'none' }

const show = el => { el.style.display = 'block' }

/* --------------------------------- Export --------------------------------- */

export {
  switchToProxy,
  switchToLogin,
  blur
}
