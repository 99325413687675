var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(require("./menu-tree.handlebars"),(depth0 != null ? lookupProperty(depth0,"menu") : depth0),{"name":"menu-tree.handlebars","hash":{"root":true},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"menu__empty-group\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"favoritesSelected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "\n    </div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "\n        <p class=\"menu__empty-group-paragraph\">\n          Nemáte žádné oblíbené položky\n        </p>\n        <p class=\"menu__empty-group-paragraph\">\n          Modul můžete zařadit mezi oblíbené kliknutím na\n          <img\n            class=\"menu__empty-group-star\"\n            src=\"/img/favourite-star-inactive.svg\"\n            width=\"16\"\n            height=\"15\"\n            alt=\"Hvězdička pro oznečení oblíbeného modulu\">\n          vedle názvu modulu\n        </p>\n\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "\n        <p class=\"menu__empty-group-paragraph\">\n          Tato skupina nemá žádné položky\n        </p>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"menu\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"menu") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"usePartial":true,"useData":true});