import codes from '../utils/codes.js'
import * as pubsub from '../utils/pubsub.js'

const ajax = (args) => {
  pubsub.publish('ajax')

  let originalUrl = args.url

  args.method = args.method.toUpperCase()

  // Establishing a promise in return
  return new Promise(function (resolve, reject) {
    let request = new window.XMLHttpRequest()

    if (args.data && (args.method === 'GET' || args.method === 'HEAD')) {
      let uri = ''

      for (var key in args.data) {
        uri += encodeURIComponent(key) + '=' + encodeURIComponent(args.data[key]) + '&'
      }

      request.open(args.method, args.url + '?' + uri, true)
    } else {
      request.open(args.method, args.url, true)
    }

    if (args.method === 'POST' || args.method === 'PUT' || args.method === 'DELETE') {
      request.setRequestHeader('Content-type', 'application/json')
    }

    request.withCredentials = true

    request.onload = function () {
      if (request.status === codes.http.UNAUTHORIZED_401) {
        pubsub.publish('ajaxNotAuthorized', { statusText: request.statusText, url: originalUrl })
      }

      if (request.status === codes.http.OK_200) {
        let response
        try {
          response = JSON.parse(request.response)
        } catch (e) {
          response = request.response
        }

        // TODO: inconsistent responses, when 200 we get json response { data, status }, when !== 200 we get raw textmessage
        resolve({
          status: request.status,
          statusText: request.statusText,
          data: response.data ? response.data : response
        })
      } else {
        resolve({
          status: request.status,
          statusText: request.statusText,
          data: request.response
        })
      }
    }

    request.onerror = function (e) {
      reject()
    }

    if (args.method === 'GET' || args.method === 'HEAD') {
      request.send()
    } else {
      request.send(JSON.stringify(args.data))
    }
  })
}

export default ajax
