import ajax from './ajax.js'

export const login = () => ajax({
  method: 'GET',
  url: '/ajax/login'
})

export const getLoginMethods = () => ajax({
  method: 'GET',
  url: '/ajax/login_methods'
})

export const favouriteModule = (uid, favorites) => ajax({
  method: 'PUT',
  url: `/ajax/users/${encodeURIComponent(uid)}`,
  data: {
    favorites
  }
})

export const logout = () => ajax({
  method: 'GET',
  url: '/ajax/logout'
})

export const superLoginCancel = () => ajax({
  method: 'GET',
  url: '/ajax/log_back'
})

export const moduleInformation = (module) => ajax({
  method: 'GET',
  url: `/ajax/support/${encodeURIComponent(module)}`
})

export const logError = (data) => ajax({
  method: 'POST',
  url: '/ajax/log',
  data
})
